import React, { useState } from 'react';
import './midpage.scss';
import { useIntl } from "react-intl";

export default function Midpage(props) {
    const dataLang = useIntl();
    const [item, setItem] = useState(2);

    const handleChangeHref = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className='DAT_Container'>
            <div className='DAT_Container_Banner'>
                <div className='DAT_Container_Banner_Container'>
                    <div className='DAT_Container_Banner_Container_Text'>
                        <div className='DAT_Container_Banner_Container_Text_Heading'>
                            {dataLang.formatMessage({ id: "info" })}
                        </div>
                        <div className='DAT_Container_Banner_Container_Text_Content'>
                            {dataLang.formatMessage({ id: "subinfo" })}
                        </div>
                    </div>
                    <div className='DAT_Container_Banner_Container_Image'>
                        <img src='./image/banner.png' alt=''></img>
                    </div>
                </div>
            </div>

            <div className='DAT_Container_Main'>
                <div className='DAT_Container_Main_Container'>
                    <ul className='DAT_Container_Main_Container_List'>
                        <li className={`DAT_Container_Main_Container_List_Item Elevator ${item === 1 ? `` : `Hidden`}`}
                            onClick={() => setItem(1)}
                        >
                            <div className="DAT_Container_Main_Container_List_Item_Fade"></div>
                            <div className="DAT_Container_Main_Container_List_Item_Left">
                                <div className="DAT_Container_Main_Container_List_Item_Left_Heading">
                                    {dataLang.formatMessage({ id: "elevwebsite" })}
                                </div>
                                <div className="DAT_Container_Main_Container_List_Item_Left_Image">
                                    <img src="/image/elevatorlogo.png" alt="" />
                                </div>
                                <a className="DAT_Container_Main_Container_List_Item_Left_Button"
                                    href="https://iot.embody.com.vn/Elev"
                                >
                                    <button>{dataLang.formatMessage({ id: "gotosite" })}</button>
                                </a>
                            </div>
                            <div className="DAT_Container_Main_Container_List_Item_Right">
                                <div className="DAT_Container_Main_Container_List_Item_Right_Heading">
                                    <div className="DAT_Container_Main_Container_List_Item_Right_Heading_Title">
                                        {dataLang.formatMessage({ id: "download" })} AIoT Elevator
                                    </div>
                                    <div className="DAT_Container_Main_Container_List_Item_Right_Heading_SubTitle">
                                        {dataLang.formatMessage({ id: "scanqr" })}
                                    </div>
                                </div>
                                <div className="DAT_Container_Main_Container_List_Item_Right_QR">
                                    <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type">
                                        <img className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Img"
                                            src="/image/elev_IOS.png"
                                            alt=""
                                        />
                                        <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download"
                                            onClick={() => handleChangeHref("https://apps.apple.com/vn/app/aiot-elevator/id6736433366")}
                                        >
                                            <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item">
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Logo">
                                                    <img src="/image/app-store_5977575.png" alt="" />
                                                </div>
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Content">
                                                    <div>Available on the</div>
                                                    <div>App Store</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type">
                                        <img className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Img"
                                            src="/image/elev_android.png"
                                            alt=""
                                        />
                                        <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download"
                                            onClick={() => handleChangeHref("https://play.google.com/store/apps/details?id=vn.com.embody.datelev&hl=vi")}
                                        >
                                            <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item">
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Logo">
                                                    <img src="/image/playstore_300218.png" alt="" />
                                                </div>
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Content">
                                                    <div>get it in</div>
                                                    <div>google play</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className={`DAT_Container_Main_Container_List_Item Energy ${item === 2 ? `` : `Hidden`}`}
                            onClick={() => setItem(2)}
                        >
                            <div className="DAT_Container_Main_Container_List_Item_Fade"></div>
                            <div className="DAT_Container_Main_Container_List_Item_Left">
                                <div className="DAT_Container_Main_Container_List_Item_Left_Heading">
                                    {dataLang.formatMessage({ id: "solarwebsite" })}
                                </div>
                                <div className="DAT_Container_Main_Container_List_Item_Left_Image">
                                    <img src="/image/energylogo.png" alt="" />
                                </div>
                                <a className="DAT_Container_Main_Container_List_Item_Left_Button"
                                    href="https://energy.embody.com.vn/"
                                >
                                    <button>{dataLang.formatMessage({ id: "gotosite" })}</button>
                                </a>
                            </div>
                            <div className="DAT_Container_Main_Container_List_Item_Right">
                                <div className="DAT_Container_Main_Container_List_Item_Right_Heading">
                                    <div className="DAT_Container_Main_Container_List_Item_Right_Heading_Title">
                                        {dataLang.formatMessage({ id: "download" })} AIoT Energy
                                    </div>
                                    <div className="DAT_Container_Main_Container_List_Item_Right_Heading_SubTitle">
                                        {dataLang.formatMessage({ id: "scanqr" })}
                                    </div>
                                </div>
                                <div className="DAT_Container_Main_Container_List_Item_Right_QR">
                                    <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type">
                                        <img className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Img"
                                            src="/image/Energy_IOS.png"
                                            alt=""
                                        />
                                        <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download"
                                            onClick={() => handleChangeHref("https://apps.apple.com/vn/app/aiot-energy/id6484594898")}
                                        >
                                            <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item">
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Logo">
                                                    <img src="/image/app-store_5977575.png" alt="" />
                                                </div>
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Content">
                                                    <div>Available on the</div>
                                                    <div>App Store</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type">
                                        <img className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Img"
                                            src="/image/Energy_android.png"
                                            alt=""
                                        />
                                        <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download"
                                            onClick={() => handleChangeHref("https://play.google.com/store/apps/details?id=vn.com.embody.aiot_energy&hl=vi")}
                                        >
                                            <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item">
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Logo">
                                                    <img src="/image/playstore_300218.png" alt="" />
                                                </div>
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Content">
                                                    <div>get it in</div>
                                                    <div>google play</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className={`DAT_Container_Main_Container_List_Item Automation ${item === 3 ? `` : `Hidden`}`}
                            onClick={() => setItem(3)}
                        >
                            <div className="DAT_Container_Main_Container_List_Item_Fade"></div>
                            <div className="DAT_Container_Main_Container_List_Item_Left">
                                <div className="DAT_Container_Main_Container_List_Item_Left_Heading">
                                    {dataLang.formatMessage({ id: "autowebsite" })}
                                </div>
                                <div className="DAT_Container_Main_Container_List_Item_Left_Image">
                                    <img src="/image/autologo.png" alt="" />
                                </div>
                                <a className="DAT_Container_Main_Container_List_Item_Left_Button"
                                    href="https://iot.embody.com.vn/"
                                >
                                    <button>{dataLang.formatMessage({ id: "gotosite" })}</button>
                                </a>
                            </div>
                            <div className="DAT_Container_Main_Container_List_Item_Right">
                                <div className="DAT_Container_Main_Container_List_Item_Right_Heading">
                                    <div className="DAT_Container_Main_Container_List_Item_Right_Heading_Title">
                                        {dataLang.formatMessage({ id: "download" })} AIoT Automation
                                    </div>
                                    <div className="DAT_Container_Main_Container_List_Item_Right_Heading_SubTitle">
                                        {dataLang.formatMessage({ id: "scanqr" })}
                                    </div>
                                </div>
                                <div className="DAT_Container_Main_Container_List_Item_Right_QR">
                                    <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type">
                                        <img className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Img"
                                            src="/image/comingsoon.png"
                                            alt=""
                                        />
                                        <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download">
                                            <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item">
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Logo">
                                                    <img src="/image/app-store_5977575.png" alt="" />
                                                </div>
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Content">
                                                    <div>Available on the</div>
                                                    <div>App Store</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type">
                                        <img className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Img"
                                            src="/image/comingsoon.png"
                                            alt=""
                                        />
                                        <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download">
                                            <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item">
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Logo">
                                                    <img src="/image/playstore_300218.png" alt="" />
                                                </div>
                                                <div className="DAT_Container_Main_Container_List_Item_Right_QR_Type_Download_Item_Content">
                                                    <div>get it in</div>
                                                    <div>google play</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
