import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "lang",
    initialState: {
        lang: "vi",
    },
    reducers: {
        setlang: (state, action) => {
            state.lang = action.payload;
        },
    },
});
