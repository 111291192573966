export const messages = {
    en: {
        info: 'Intelligent Monitoring System',
        subinfo: 'Comprehensive technology designed to provide convenience in industrial monitoring, device control and data analysis',
        gotosite: 'Go to site',
        download: 'Download',
        comingsoon: 'Coming soon',
        scanqr: 'Use your phone camera to scan the QR code below',
        elevwebsite: 'AIoT Elev Website',
        solarwebsite: 'AIot Energy Website',
        autowebsite: 'AIoT Automation Website',
    },
    vi: {
        info: 'Hệ thống giám sát thông minh',
        subinfo: 'Công nghệ toàn diện được thiết kế để mang lại sự thuận tiện trong giám sát công nghiệp, điều khiển thiết bị và phân tích dữ liệu',
        gotosite: 'Đi đến trang',
        download: 'Tải xuống',
        comingsoon: 'Sắp ra mắt',
        scanqr: 'Sử dụng camera điện thoại của bạn để quét mã QR dưới đây',
        elevwebsite: 'Trang AIoT Elev',
        solarwebsite: 'Trang AIot Energy',
        autowebsite: 'Trang AIoT Automation',
    }
}