import React, { useEffect, useRef, useState } from 'react';
import './ProcessData.scss';
import axios from 'axios';
import DataTable from "react-data-table-component";
import { IoMdAddCircleOutline } from "react-icons/io";
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";

export default function ProcessData(props) {
    const [dataExcel, setDataExcel] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataDetail, setDataDetail] = useState(null);
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [dataToggle, setDataToggle] = useState({
        chart: false,
        daily5min: false,
        dailyTime: false,
        monthly: false,
    });

    //New-Version
    const idRef = useRef(null);
    const chartRef = useRef(null);
    const daily5minRef = useRef(null);
    const dailyTimeRef = useRef(null);
    const monthlyRef = useRef(null);

    //Remove first row
    const filteredDataExcel = dataExcel[0]?.id === "ID" ? dataExcel.slice(1) : dataExcel;
    const column = [
        { name: "No.", selector: row => row.no, width: "70px" },
        { name: "ID", selector: row => row.id, width: "200px" },
        { name: "Chart", selector: row => row.chart, width: "100px" },
        { name: "Daily 5 min", selector: row => row.daily_5min, width: "100px" },
        { name: "Daily Time", selector: row => row.daily_time, width: "100px" },
        { name: "Monthly", selector: row => row.monthly, width: "100px" },
        {
            name: "Action",
            selector: row => (
                <div style={{ cursor: "pointer", display: "flex" }}>
                    <AiFillEdit className="edit-icon" size={20} color='#0000ff' onClick={() => { handleDetail(row.id); setModal(true); }} />
                    <MdDelete className="delete-icon" size={20} color='#f44336' onClick={() => { handleDelete(row.id); setModal(true); setModalType('delete') }} />
                </div>
            ),
            width: "100px"
        }
    ];

    //Open Modal
    const handleOpenModal = () => {
        setDataDetail(null);
        setModal(true);
        setModalType('');
        setDataToggle({ chart: false, daily5min: false, dailyTime: false, monthly: false });
    };

    //Close Modal
    const handleCloseModal = () => {
        setModal(false);
        setDataDetail(null);
        setModalType('');
    };

    //Toggle Status Switch
    const handleToggle = (toggleKey) => {
        setDataToggle(prevState => ({
            ...prevState,
            [toggleKey]: !prevState[toggleKey]
        }));
    };

    const handleAdd = (e) => {
        e.preventDefault();
        const lastIdNumber = dataExcel.length > 1
            ? parseInt(dataExcel[dataExcel.length - 1].no, 10) + 1
            : 1;

        const id = idRef.current?.value;
        const chart = chartRef.current?.checked ? "true" : "false";
        const daily5min = daily5minRef.current?.checked ? "true" : "false";
        const dailyTime = dailyTimeRef.current?.checked ? "true" : "false";
        const monthly = monthlyRef.current?.checked ? "true" : "false";

        if (lastIdNumber) {
            const newData = {
                no: lastIdNumber,
                id: id,
                chart: chart,
                daily_5min: daily5min,
                daily_time: dailyTime,
                monthly: monthly
            };
            const updatedDataExcel = [...dataExcel, newData];
            const result = updatedDataExcel.map(Object.values);
            axios.post(`${process.env.REACT_APP_PORTAL}/api/update-data/`, { newData: result })
                .then(() => {
                    fetchData();
                    setModal(false);
                })
                .catch((error) => {
                    console.error("Error deleting data: ", error);
                });
        } else {
            alert('Data not found');
        }
    };

    const handleDelete = (dataId) => {
        const findData = dataExcel.find(item => item.id === dataId);
        if (findData) {
            const valuesArray = Object.values(findData);
            setDataDetail(valuesArray);
        } else {
            console.log('Data not found');
        }
    };

    const handleConfirmDelete = (e) => {
        const dataDelete = dataExcel.filter(item => item.id !== idRef.current.value);
        const result = dataDelete.map(Object.values);
        axios.post(`${process.env.REACT_APP_PORTAL}/api/update-data/`, { newData: result })
            .then(() => {
                fetchData();
            })
            .catch((error) => {
                console.error("Error deleting data: ", error);
            });
        setModal(false);
    };

    const handleDetail = (dataId) => {
        const findData = dataExcel.find(item => item.id === dataId);
        if (findData) {
            const valuesArray = Object.values(findData);
            setDataDetail(valuesArray);
        } else {
            console.log('Data not found');
        }
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        const id = dataDetail?.[1];
        const chart = chartRef.current?.checked ? "true" : "false";
        const daily5min = daily5minRef.current?.checked ? "true" : "false";
        const dailyTime = dailyTimeRef.current?.checked ? "true" : "false";
        const monthly = monthlyRef.current?.checked ? "true" : "false";

        const findIndex = dataExcel.findIndex(item => item.id === id);
        if (findIndex === -1) {
            alert('Data not found');
            return;
        }

        if (id) {
            const updateItem = {
                ...dataExcel[findIndex],
                chart,
                daily_5min: daily5min,
                daily_time: dailyTime,
                monthly
            };

            const updatedDataExcel = [...dataExcel];
            updatedDataExcel[findIndex] = updateItem;
            const result = updatedDataExcel.map(Object.values);

            axios.post(`${process.env.REACT_APP_PORTAL}/api/update-data/`, { newData: result })
                .then(() => {
                    fetchData();
                    setModal(false);
                })
                .catch((error) => {
                    console.error("Error updating data: ", error);
                });
        } else {
            alert('Please fill in all fields');
        }
    };


    //Get Data From Call Api
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_PORTAL}/api/get-data/`);
            const formattedData = response.data.map(row => ({
                no: row[0],
                id: row[1],
                chart: row[2],
                daily_5min: row[3],
                daily_time: row[4],
                monthly: row[5]
            }));
            const dataFiltered = formattedData.filter(item =>
                Object.values(item).some(value => value !== null)
            );
            setDataExcel(dataFiltered || []);
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    //Asign data to input
    useEffect(() => {
        if (dataDetail) {
            idRef.current.value = dataDetail?.[1] || "";
            const isChartValue = dataDetail?.[2] === "true";
            const isDaily5minValue = dataDetail?.[3] === "true";
            const isDailyTimeValue = dataDetail?.[4] === "true";
            const isMonthlyValue = dataDetail?.[5] === "true";
            if (chartRef.current && daily5minRef.current && dailyTimeRef.current && monthlyRef.current) {
                chartRef.current.checked = isChartValue;
                daily5minRef.current.checked = isDaily5minValue;
                dailyTimeRef.current.checked = isDailyTimeValue;
                monthlyRef.current.checked = isMonthlyValue;
            }
            setDataToggle({
                chart: isChartValue,
                daily5min: isDaily5minValue,
                dailyTime: isDailyTimeValue,
                monthly: isMonthlyValue
            });
        }
    }, [dataDetail]);

    return (
        <div className="DAT_ProcessData">
            <h2 className="DAT_ProcessData_Title">Setup Device Status</h2>
            <div className='DAT_ProcessData_AddNew'>
                <button className='DAT_ProcessData_AddNew_Button' onClick={handleOpenModal}>
                    New <IoMdAddCircleOutline size={20} />
                </button>
            </div>
            {loading ? (
                <div style={{ paddingTop: '20px' }}>
                    <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="6" width="2.8" height="12"><animate id="spinner_CcmT" begin="0;spinner_IzZB.end-0.1s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98" /><animate begin="0;spinner_IzZB.end-0.1s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98" /></rect><rect x="5.8" y="6" width="2.8" height="12"><animate begin="spinner_CcmT.begin+0.1s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98" /><animate begin="spinner_CcmT.begin+0.1s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98" /></rect><rect x="10.6" y="6" width="2.8" height="12"><animate begin="spinner_CcmT.begin+0.2s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98" /><animate begin="spinner_CcmT.begin+0.2s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98" /></rect><rect x="15.4" y="6" width="2.8" height="12"><animate begin="spinner_CcmT.begin+0.3s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98" /><animate begin="spinner_CcmT.begin+0.3s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98" /></rect><rect x="20.2" y="6" width="2.8" height="12"><animate id="spinner_IzZB" begin="spinner_CcmT.begin+0.4s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98" /><animate begin="spinner_CcmT.begin+0.4s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98" /></rect></svg>
                </div>
            ) : (
                <>
                    {dataExcel && dataExcel.length > 0 ? (
                        <DataTable
                            columns={column}
                            data={filteredDataExcel}
                            pagination
                            fixedHeader={true}
                            customStyles={{
                                cells: {
                                    style: {
                                        borderRight: "1px solid #ddd",
                                    },
                                }
                            }}
                        />
                    ) : (
                        <div style={{ textAlign: "center", padding: '20px', color: '#666' }}>
                            <p>No records found</p>
                        </div>
                    )}
                </>
            )}
            {modal &&
                <>
                    <div className="DAT_ProcessData_CloseBackground" onClick={handleCloseModal}>
                    </div>
                    <div className="DAT_ProcessData_Modal">
                        {modalType === 'delete' ? 'Are you sure delete this data?' : ''}
                        <div className='DAT_ProcessData_Modal_Toggle'>
                            <label className='DAT_ProcessData_Modal_Toggle_Key'>ID</label>
                            <input
                                className='DAT_ProcessData_Modal_Toggle_ID'
                                type="text"
                                ref={idRef}
                                required
                                readOnly={!!dataDetail}
                            />
                        </div>

                        {modalType === 'delete'
                            ?
                            <div className='DAT_ProcessData_Modal_Action'>
                                <button className='DAT_ProcessData_Modal_Action_Save' type='button' onClick={(e) => handleConfirmDelete(e)}>
                                    Save
                                </button>
                                <button className='DAT_ProcessData_Modal_Action_Close' onClick={handleCloseModal}>Close</button>
                            </div>
                            :
                            <>
                                <div className='DAT_ProcessData_Modal_Toggle'>
                                    <label className='DAT_ProcessData_Modal_Toggle_Key'>Chart</label>
                                    <label className='DAT_ProcessData_Modal_Toggle_Switch'>
                                        <input
                                            type="checkbox"
                                            ref={chartRef}
                                            checked={dataToggle.chart}
                                            onChange={() => handleToggle('chart')}
                                        />
                                        <span className='DAT_ProcessData_Modal_Toggle_Switch_Slider'></span>
                                    </label>
                                </div>

                                <div className='DAT_ProcessData_Modal_Toggle'>

                                    <label className='DAT_ProcessData_Modal_Toggle_Key'>Daily 5 min</label>

                                    <label className='DAT_ProcessData_Modal_Toggle_Switch'>
                                        <input
                                            type="checkbox"
                                            ref={daily5minRef}
                                            checked={dataToggle.daily5min}
                                            onChange={() => handleToggle('daily5min')}
                                        />
                                        <span className='DAT_ProcessData_Modal_Toggle_Switch_Slider'></span>
                                    </label>
                                </div>


                                <div className='DAT_ProcessData_Modal_Toggle'>

                                    <label className='DAT_ProcessData_Modal_Toggle_Key'>Daily Time</label>

                                    <label className='DAT_ProcessData_Modal_Toggle_Switch'>
                                        <input
                                            type="checkbox"
                                            ref={dailyTimeRef}
                                            checked={dataToggle.dailyTime}
                                            onChange={() => handleToggle('dailyTime')}
                                        />
                                        <span className='DAT_ProcessData_Modal_Toggle_Switch_Slider'></span>
                                    </label>
                                </div>

                                <div className='DAT_ProcessData_Modal_Toggle'>

                                    <label className='DAT_ProcessData_Modal_Toggle_Key'>Monthly</label>

                                    <label className='DAT_ProcessData_Modal_Toggle_Switch'>
                                        <input
                                            type="checkbox"
                                            ref={monthlyRef}
                                            checked={dataToggle.monthly}
                                            onChange={() => handleToggle('monthly')}
                                        />
                                        <span className='DAT_ProcessData_Modal_Toggle_Switch_Slider'></span>
                                    </label>
                                </div>

                                <div className='DAT_ProcessData_Modal_Action'>
                                    <button className='DAT_ProcessData_Modal_Action_Save' type='button' onClick={(e) => dataDetail ? handleUpdate(e) : handleAdd(e)}>
                                        Save
                                    </button>
                                    <button className='DAT_ProcessData_Modal_Action_Close' onClick={handleCloseModal}>Close</button>
                                </div>
                            </>
                        }
                    </div>
                </>
            }
        </div>
    );
}