import React from 'react';
import './navpane.scss';
import { signal } from "@preact/signals-react";
import { IoMdGlobe } from "react-icons/io";
import { useSignals } from '@preact/signals-react/runtime';
import { useDispatch, useSelector } from "react-redux";
import langslice from "../Redux/langslice";

const langNav = signal(false);
const langStateNav = signal([false, false]);

export default function Navpane(props) {
    useSignals();

    const lang = useSelector((state) => state.lang.lang);
    const rootDispatch = useDispatch();

    const handleOutsideLang = () => {
        setTimeout(() => {
            if (langStateNav.value[1] === false) {
                langNav.value = false;
                langStateNav.value = [false, false];
            }
            clearTimeout();
        }, 250);
    };

    const handleLang = async (lang_) => {
        rootDispatch(langslice.actions.setlang(lang_));
    };

    return (
        <>
            <div className='navpane'>
                <div className='navpane_left'>
                    <div className='navpane_left_pagetitle'>
                        <a className='navpane_left_pagetitle_logo' href='https://datgroup.com.vn/'>
                            <img src='./dat_icon/Embody_APP_27.png' alt=''></img>
                        </a>
                    </div>
                </div>

                <div className='navpane_right'>
                    <div className='navpane_right_languagebutton'
                        onClick={() => {
                            langNav.value = true;
                            langStateNav.value = [true, false];
                        }}
                        onMouseLeave={() => handleOutsideLang()}
                    >
                        <button><IoMdGlobe color='white' size={30} /></button>

                        <div className='navpane_right_languagebutton_language'>
                            <p>{lang === "vi" ? "Vi" : "En"}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='DAT_NavLang'
                style={{ display: langNav.value ? 'block' : 'none' }}
                onMouseEnter={() => langStateNav.value = [true, true]}
                onMouseLeave={() => {
                    langNav.value = false;
                    langStateNav.value = [false, false];
                }}
            >
                <div className='DAT_NavLang_Item'
                    style={{
                        backgroundColor: lang === "vi" ? "rgba(43, 195, 253)" : "white",
                        color: lang === "vi" ? "white" : "black",
                    }}
                    onClick={() => handleLang("vi")}
                >
                    <span>Tiếng Việt</span>
                </div>
                <div className='DAT_NavLang_Item'
                    style={{
                        backgroundColor: lang === "en" ? "rgba(43, 195, 253)" : "white",
                        color: lang === "en" ? "white" : "black",
                    }}
                    onClick={() => handleLang("en")}
                >
                    <span>English</span>
                </div>
            </div>
        </>
    );
}