import React, { useEffect } from 'react';
import './Mapmobile.scss';
import axios from 'axios';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

export default function Mapmobile(props) {
    const loader = new Loader({
        apiKey: process.env.REACT_APP_GGKEY,
        version: "weekly",
        libraries: ["places"],
    });

    const initMap = async (data, lang) => {
        loader.load().then(async (google) => {
            const defaultProps = {
                center: {
                    lat: 16.021844063892495,
                    lng: 107.19023466088318,
                },
                zoom: 5.6,
                mapId: "DEMO_MAP_ID",
                fullscreenControl: false,
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
            };

            const { Map } = await google.maps.importLibrary("maps");
            const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
            let map = new Map(document.getElementById("map"), defaultProps);

            const markerList = data.map((item, index) => {
                const myLatlng = { lat: 16.021844063892495, lng: 108.19023466088318 };

                let infoWindow = new google.maps.InfoWindow({
                    content: 'Your position',
                    position: myLatlng,
                });

                map.addListener("click", () => { infoWindow.close() });

                const priceTag = document.createElement("div");
                const src = item?.img ? item.img : `/dat_picture/elev.jpg`
                priceTag.className = "price-tag";
                priceTag.innerHTML = `<img src='${src}'></img>`

                const marker = { lat: parseFloat(item.lat_), lng: parseFloat(item.long_) };
                const markerElement = new AdvancedMarkerElement({
                    position: marker,
                    map: map,
                    title: item.name_,
                    content: priceTag,
                });

                markerElement.addListener("click", () => {
                    // Close the current InfoWindow.
                    infoWindow.close();
                    // Create a new InfoWindow.
                    infoWindow = new google.maps.InfoWindow({
                        anchor: markerElement,
                        map: map,
                        shouldFocus: false,
                    });

                    infoWindow.setContent(`                 
                        <div class='InfoWindow'>
                            <div class='InfoWindow_Tit'>${item.name_}</div>
                           
                            <div class='InfoWindow_Content'>
                                <div class='InfoWindow_Content_Item'>
                                    <div class='InfoWindow_Content_Item_Tit'>${lang === 'en' ? 'Address' : 'Địa chỉ'}:</div> 
                                    <div class='InfoWindow_Content_Item_Content'>${item.addr_}</div>                               
                                </div>

                                <div class='InfoWindow_Content_Item'>
                                    <div class='InfoWindow_Content_Item_Tit'>${lang === 'en' ? 'Company' : 'Công ty'}:</div> 
                                    <div class='InfoWindow_Content_Item_Content'>${item.company_}</div>                               
                                </div>
                                
                                <div class='InfoWindow_Content_Item'>
                                    <div class='InfoWindow_Content_Item_Tit'>${lang === 'en' ? 'Phone' : 'SĐT'}:</div> 
                                    <div class='InfoWindow_Content_Item_Content'>${item.phone_}</div>                               
                                </div>
                            </div>
                        </div>
                    `);
                });

                return markerElement;
            });

            new MarkerClusterer({ markers: markerList, map: map });
        })
    };

    const callApi = (method, url, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (method === 'get') {
                    await axios.get(url, {
                        headers: {
                            token: JSON.parse(localStorage.getItem('token')) || JSON.parse(sessionStorage.getItem('token'))
                        },
                        withCredentials: true
                    }).then(
                        function (res) {
                            resolve(res.data)
                        }
                    )
                } else {
                    return await axios.post(url, data, {
                        headers: {
                            token: JSON.parse(localStorage.getItem('token')) || JSON.parse(sessionStorage.getItem('token'))
                        },
                        withCredentials: true
                    }).then(
                        function (res) {
                            resolve(res.data)
                        }
                    )
                }
            } catch (e) {
                reject(e);
            }
        });
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        // http://192.168.68.30:3001/Mapmobile?user=dat_master&partnerid=1&type=%27master%27

        const getAllPlant = async () => {
            let d = await callApi('post', process.env.REACT_APP_IOT + '/data/getAllPlant2', {
                usr: urlParams.get('user'),
                partnerid: urlParams.get('partnerid'),
                type: urlParams.get('type'),
                system: urlParams.get('system')
            })
            console.log(d);
            if (d.status) {
                initMap(d.data, urlParams.get('lang'));
            } else {
                initMap([]);
            }
        };
        getAllPlant();
    }, []);

    return (
        <div id='map' className='DAT_Mapmobile'></div>
    );
}